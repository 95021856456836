// Dependencies
import React, { useEffect } from "react"
import { graphql, navigate } from "gatsby";
import PropTypes from "prop-types";
// Hooks & Helpers
// import useLocalStorage from "../hooks/useLocalStorage"
// Wrappers
import Layout from "../wrappers/Layout"
import { useRegionInPageContext, useStoreContext } from '../wrappers/Shop'
// Components
import Sections from "../components/Sections"

const IndexPage = ({ data, pageContext, location }) => {

	const { sections } = data.contentfulHomepage

	const { userCurrency } = useStoreContext()

	useRegionInPageContext(pageContext?.region?.slug)

	// useEffect(() => {
	// 	if (userCurrency?.slug && userCurrency.slug !== pageContext?.region?.slug) {
	// 		navigate(`/${userCurrency.slug}`)
	// 	}		
	// }, [userCurrency])

	return (
		<Layout location={location}>
			<Sections sections={sections} />
		</Layout>
	)
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default IndexPage

export const IndexPagePageQuery = graphql`
  query($homepageId: String!) {
    allContentfulSettings {
			nodes {
				defaultTitle
			}
    }
		contentfulHomepage(contentful_id: { eq: $homepageId }) {
			title
			sections {
				... on Node {
					... on ContentfulHomepageSection {
						...HomepageSection
					}
					... on ContentfulHeroModule {
						...HeroModule
					}
				}
			}
		}
  }
`
